import { Example } from "./Example";

import styles from "./Example.module.css";

const DEFAULT_EXAMPLES: string[] = [
    "What are the advantages and side effects of mRNA vaccine?",
    "Please make a summarization of mRNA technology",
    "Please explain how mRNA vaccine was evaluated?"
];

const GPT4V_EXAMPLES: string[] = [
    "Please compare the impact of inflation and financial markets.",
    "What is the expected trend for the NASDAQ index over the next five years? Compare it to the past Gold performance",
    "Can you identify any correlation between cryptocurrenty and stock market trends?"
];

interface Props {
    onExampleClicked: (value: string) => void;
    useGPT4V?: boolean;
}

export const ExampleList = ({ onExampleClicked, useGPT4V }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {(useGPT4V ? GPT4V_EXAMPLES : DEFAULT_EXAMPLES).map((question, i) => (
                <li key={i}>
                    <Example text={question} value={question} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
