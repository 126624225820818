import { Outlet, NavLink, Link } from "react-router-dom";

import github from "../../assets/hulkdesign-ai-logo.png";

import styles from "./Layout.module.css";

import { useLogin } from "../../authConfig";

import { LoginButton } from "../../components/LoginButton";

const Layout = () => {
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <a href="https://hulkdesign.com/spotlight" target={"_blank"} title="Spotlight link">
                        <img
                            src={github}
                            alt="Spotlight logo"
                            aria-label="Link to Spotlight"
                            width="20px"
                            height="20px"
                            className={styles.githubLogo}
                        />
                    </a>
                    <Link to="/" className={styles.headerTitleContainer}>
                        <h3 className={styles.headerTitle}>Spotlight DATA Demo</h3>
                    </Link>
                    <nav>
                        <ul className={styles.headerNavList}>
                            <li className={styles.headerNavLeftMargin}>
                            </li>
                        </ul>
                    </nav>
                    <h4 className={styles.headerRightText}></h4>
                    {useLogin && <LoginButton />}
                </div>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;
